<template lang="pug">
    .form-input-label.my-2
        .d-flex.flex-column
            small.accent--text(v-if="required") Required
            .label(v-if="value && $vuetify.breakpoint.smAndDown") {{value.split("*").join('')}}
</template>

<script>
export default {
  name: "FormInputLabel",
  props: {
    value: { type: String, default: null },
    required: { type: Boolean, default: true },
  },
};
</script>
