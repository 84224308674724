<template lang="pug">
    .pro-account-offer
        .white.pa-5
            .intro(v-if="!done")
                h2 Get FREE unlimited access to SeriCity by sharing your feedback
                v-divider.my-3(:style="{'max-width':'700px'}")
                p This page is only for our inner circle of teachers. It takes about 5 minutes to complete.
                p Share your feedback about SeriCity and get 2 months of FREE, unlimited access to all lessons.

            v-card(color="grey lighten-5" flat).pa-5
                template(v-if="done")
                    h2.text-center.mb-0 Terrific {{name}}! You've just earned yourself FREE UNLIMITED access to SeriCity for {{numFreeMonths}} month{{numFreeMonths > 2 ? 's' : ''}}.
                template(v-else)
                    v-form#offer-form(v-model="form", ref="form", @input="handleFormChange", @submit.prevent="handleFormSubmit")
                        v-row.flex-column-reverse.flex-md-row(dense)
                            v-col(cols="12" md="6")
                                .header.d-flex.mb-3.justify-start.align-center
                                    v-subheader.ml-n4.flex-grow-0(:style="{width:'100%'}") Part {{part + 1}} of {{parts}}
                                    v-progress-linear(:value="(part + 1) / parts * 100").flex-grow-1
                                v-scroll-x-transition
                                    .part(v-show="part===0")
                                        FormInputLabel
                                        v-text-field(outlined name="$name", v-model="name", label="Your name *", placeholder="Maria Berg", hint="Full name please?", autocomplete='off', autocorrect="off", spellcheck="false", background-color="white", :rules="rules.name")
                                        
                                        FormInputLabel
                                        v-text-field(outlined name="$email" v-model="email", label="Your email *", placeholder="m.berg@example.com", hint="The email address to use for a PRO account", background-color="white", :rules="rules.email")
                                        
                                        v-text-field(outlined name="$instagram" v-model="instagram", label="Instagram", placeholder="@your_instagram_id", background-color="white", :rules="rules.handle")
                                        v-text-field(outlined name="$telegram" v-model="telegram", label="Telegram", placeholder="@your_telegram_id", background-color="white", :rules="rules.handle")
                                    
                                        FormBackNextButtons(disableBackButton @click:next="goToNext", @click:previous="goToPrev")
                                        
                                
                                v-scroll-x-transition
                                    .part(v-show="part===1")
                                        FormInputLabel(:value="questions.positiveFeedback")
                                        v-textarea(outlined v-model="positiveFeedback", :label="$vuetify.breakpoint.mdAndUp ? questions.positiveFeedback : null", placeholder="I like..." rows="5", background-color="white", :rules="rules.isRequired")
                                        
                                        FormInputLabel(:value="questions.negativeFeedback")
                                        v-textarea(outlined v-model="negativeFeedback", :label="$vuetify.breakpoint.mdAndUp ? questions.negativeFeedback : null", placeholder="I don't like...", rows="3", background-color="white", :rules="rules.isRequired")
                                        
                                        FormInputLabel(:value="questions.numClassHours")
                                        v-textarea(outlined v-model="numClassHours", :label="$vuetify.breakpoint.mdAndUp ? questions.numClassHours : null", rows="3", background-color="white", hint="What TV Shows will you teach? How many class hours per week?", placeholder="I plan to teach Brooklyn Nine Nine for 9 class hours a week...", :rules="rules.isRequired")

                                        FormBackNextButtons(@click:next="goToNext", @click:previous="goToPrev")

                                v-scroll-x-transition
                                    .part(v-show="part===2")
                                        FormInputLabel(:value="questions.surprises")
                                        v-textarea(outlined v-model="surprises", :label="$vuetify.breakpoint.mdAndUp ? questions.surprises : null", rows="3", placeholder="I was surprised because...", background-color="white", :rules="rules.isRequired")
                                        
                                        FormInputLabel(:value="questions.typesWhoShouldUseIt")
                                        v-textarea(outlined v-model="typesWhoShouldUseIt", :label="$vuetify.breakpoint.mdAndUp ? questions.typesWhoShouldUseIt : null", placeholder="SeriCity is good for...", rows="3", background-color="white", :rules="rules.isRequired")
                                        
                                        FormInputLabel(:value="questions.typesWhoShouldNotUseIt", :required="false")
                                        v-text-field(outlined v-model="typesWhoShouldNotUseIt", :label="$vuetify.breakpoint.mdAndUp ? questions.typesWhoShouldNotUseIt : null", placeholder="I DON'T recommend SeriCity for..." background-color="white")

                                        FormBackNextButtons(@click:next="goToNext", @click:previous="goToPrev")
                                    
                                v-scroll-x-transition
                                    .part(v-show="part===3")
                                        v-radio-group(v-model="accessRequestType")
                                            v-radio(label="I want 2 FREE months of unlimited access (in exchange for sharing on social media)", value="yes-post-to-social-media")
                                            v-card.pa-4(color="grey lighten-4" flat).mt-2.mb-8
                                                small.text--secondary.mb-0 For 2 FREE months of unlimited access, please submit this form, then post about SeriCity on social media (Instagram, Facebook, Twitter, or a Telegram group / channel).
                                            v-radio(label="I want 1 FREE month of unlimited access (sharing is appreciated, but not required)", value="no-post-to-social-media")

                                        v-card.pa-4(color="grey lighten-4" flat).mt-2.mb-8
                                            small.text--secondary.mb-0
                                                v-icon.mr-2 mdi-information-variant
                                                | By submitting this form, you are giving permission to use your feedback as a testimonial, to help us market and grow SeriCity. Is that okay with you?
                                        FormBackNextButtons(type="submit", @click:submit="handleFormSubmit", @click:previous="goToPrev")

                                        v-alert#form-error-message(v-if="error", type="error" outlined border="left").mt-3
                                            .text-left Oops! Please go back through the form and double-check you've entered all the required info.
                            

                            v-col(cols="12" md="6")
                                v-img(src="@/assets/images/social_sharing.svg" max-width="500px" contain).mx-12

            .mt-6.ml-2
                p.text-center Your feedback will be used to improve SeriCity right away and also as a testimonial to help with marketing.
</template>

<script>
import FormInputLabel from "@/components/Forms/FormInputLabel.vue";
import FormBackNextButtons from "@/components/Forms/FormBackNextButtons.vue";
import { get } from "vuex-pathify";
export default {
  name: "ProAccountOffer",
  components: { FormInputLabel, FormBackNextButtons },
  data() {
    return {
      done: false,
      form: null,
      error: false,
      part: 0,
      parts: 4,
      name: null,
      email: null,
      instagram: null,
      telegram: null,
      accessRequestType: "no-post-to-social-media",
      positiveFeedback: null,
      negativeFeedback: null,
      numClassHours: null,
      surprises: null,
      typesWhoShouldUseIt: null,
      typesWhoShouldNotUseIt: null,
      questions: {
        surprises: "What surprised you about SeriCity? *",
        typesWhoShouldUseIt:
          "Who is the kind of teacher or student who should use SeriCity? *",
        typesWhoShouldNotUseIt: "Who should NOT use SeriCity?",
        positiveFeedback: "What do you like about using SeriCity? *",
        negativeFeedback: "What do you NOT like about SeriCity? *",
        numClassHours:
          "How do you plan to use your FREE UNLIMITED subscription? *",
      },
      rules: {
        isRequired: [(v) => (v && v.length > 0) || "This field is required"],
        name: [
          (v) => !!v || "Name is required",
          (v) => (v && v.length >= 4) || "Name must be more than 4 characters",
          (v) =>
            (v && v.includes(" ")) ||
            "Please make sure to enter your full name",
        ],
        email: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid",
        ],
        handle: [
          (v) =>
            (v && v.length > 0 ? v.startsWith("@") : null) ||
            "This is not required, but username must start with '@'",
        ],
      },
    };
  },
  computed: {
    ...get({ hostname: "hostname" }),
    numFreeMonths() {
      return this.accessRequestType === "no-post-to-social-media" ? 1 : 2;
    },
  },
  methods: {
    goToNext() {
      if (this.part < this.parts) {
        this.part++;
        document.getElementById("offer-form").scrollIntoView();
      }
    },
    goToPrev() {
      if (this.part > 0) {
        this.part = this.part - 1;
        document.getElementById("offer-form").scrollIntoView();
      }
    },
    handleFormChange() {
      this.error = null;
    },
    async handleFormSubmit() {
      await this.$refs.form.validate();

      this.error = !this.form;

      if (!this.error) {
        const {
          name,
          email,
          instagram,
          telegram,
          accessRequestType,
          positiveFeedback,
          negativeFeedback,
          numClassHours,
          surprises,
          typesWhoShouldUseIt,
          typesWhoShouldNotUseIt,
        } = this;

        const meta = {
          name,
          email,
          subject: "SeriCity Feedback + Access Request",
          replyTo: "@",
          accessKey: "b929730e-de87-450c-9acf-cfd7dafe505f",
        };
        const res = await fetch("https://api.staticforms.xyz/submit", {
          method: "POST",
          body: JSON.stringify({
            ...meta,
            $accessRequestType: accessRequestType,
            $positiveFeedback: positiveFeedback,
            $negativeFeedback: negativeFeedback,
            $numClassHours: numClassHours,
            $surprises: surprises,
            $typesWhoShouldUseIt: typesWhoShouldUseIt,
            $typesWhoShouldNotUseIt: typesWhoShouldNotUseIt,
            $telegram: telegram,
            $instagram: instagram,
          }),
          headers: { "Content-Type": "application/json" },
        });

        const json = await res.json();

        if (json.success) {
          const firstName = this.name.split(" ")[0];
          const toastMessage = `Awesome ${firstName}! Thanks for your feedback. We'll get back to you about your request for ${this.numFreeMonths} month(s) of FREE unlimited access.`;
          this.$router.push({ path: "/lessons", query: { toastMessage } });
        } else {
          this.error = json.message;
          document.getElementById("form-error-message").scrollIntoView();
        }
      }
    },
  },
};
</script>
