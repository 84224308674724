<template lang="pug">
    .buttons.mt-4.text-md-right.d-flex.flex-column-reverse.flex-md-row
        v-btn(color="primary" text :disabled="disableBackButton"  x-large :block="$vuetify.breakpoint.smAndDown", @click="$emit('click:previous')").mr-3.mt-4.mt-md-0
            v-icon.mr-2 mdi-chevron-left 
            | Back
        v-btn(v-if="type === 'next'", :disabled="disableNextButton", x-large color="primary", :block="$vuetify.breakpoint.smAndDown", @click="$emit('click:next')")
            | Next
            v-icon.ml-2 mdi-arrow-right
        v-btn(v-else-if="type === 'submit'", x-large color="primary" @click="$emit('click:submit')") Submit
            v-icon.ml-2 mdi-arrow-right
</template>

<script>
export default {
  name: "FormBackNextButtons",
  props: {
    type: { type: String, default: "next" },
    disableBackButton: { type: Boolean, default: false },
    disableNextButton: { type: Boolean, default: false },
  },
};
</script>
